import React, {Component} from 'react';
import emailjs from 'emailjs-com';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import * as validation from '../utils/FormValidation';
import {Button, Col, Form} from "react-bootstrap";
import Layout from "../components/layout";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            formErrors: {
                name: '',
                email: '',
                subject: '',
                message: '',
            },
        };
    }

    toastifySuccess() {
        toast.success('Form sent!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: 'submit-feedback success',
        });
    }

    toastifyFail() {
        toast.error('Form failed to send!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: 'submit-feedback fail',
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        // if (validation.formValid(this.state)) {
        // Handle form validation success
        const {name, email, subject, message} = this.state;

        // Set template params????????
        let templateParams = {
            name: name,
            email: email,
            subject: subject,
            message: message,
        };

        emailjs.send('service_8gk16xb', 'template_peo9eeo', templateParams, 'user_ONi2HdAOTONvHEGCV6fyg');

        this.toastifySuccess();
        this.resetForm();
        // } else {
        //     // Handle form validation failure
        //     console.error('FORM INVALID - DISPLAY ERROR MESSAGE');
        //     this.toastifyFail();
        // }
    };

    resetForm() {
        this.setState({
            name: '',
            email: '',
            subject: '',
            message: '',
        });
    }

    handleChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        let formErrors = {...this.state.formErrors};

        switch (name) {
            case 'name':
                formErrors.name = value.length < 1 ? 'Please enter your name.' : '';
                break;
            case 'email':
                formErrors.email = validation.emailRegex.test(value) ? '' : 'Please enter a valid email address.';
                break;
            case 'subject':
                formErrors.subject = value.length < 1 ? 'Please enter a subject.' : '';
                break;
            case 'message':
                formErrors.message = value.length < 1 ? 'Please enter a message' : '';
                break;
            default:
                break;
        }
        this.setState({formErrors, [name]: value});
    };

    render() {
        const {formErrors} = this.state;

        return (
            <Layout>
                <Form.Group>
                    <Form id='contact-form' onSubmit={this.handleSubmit} noValidate>
                        <Form.Row>
                            <Col>
                                <Form.Control
                                    type='text'
                                    name='name'
                                    value={this.state.name}
                                    className={`form-control formInput ${formErrors.name.length > 0 ? 'error' : null}`}
                                    onChange={this.handleChange}
                                    placeholder='Name'
                                    noValidate
                                ></Form.Control>
                                {formErrors.name.length > 0 && (
                                    <span className='errorMessage'>{formErrors.name}</span>
                                )}
                            </Col>
                        </Form.Row>
                        <br/>
                        <Form.Row>
                            <Col>
                                <Form.Control
                                    type='email'
                                    name='email'
                                    value={this.state.email}
                                    className={`form-control formInput ${formErrors.email.length > 0 ? 'error' : null}`}
                                    onChange={this.handleChange}
                                    placeholder='Email'
                                    noValidate
                                ></Form.Control>
                                {formErrors.email.length > 0 && (
                                    <span className='errorMessage'>{formErrors.email}</span>
                                )}
                            </Col>
                        </Form.Row>
                        <br/>
                        <Form.Row>
                            <Col>
                                <input
                                    type='subject'
                                    name='subject'
                                    value={this.state.subject}
                                    className={`form-control formInput ${formErrors.subject.length > 0 ? 'error' : null
                                    }`}
                                    onChange={this.handleChange}
                                    placeholder='Subject'
                                    noValidate
                                ></input>
                                {formErrors.subject.length > 0 && (
                                    <span className='errorMessage'>{formErrors.subject}</span>
                                )}
                            </Col>
                        </Form.Row>
                        <br/>
                        <Form.Row>
                            <Col>
                            <textarea
                                rows='5'
                                name='message'
                                value={this.state.message}
                                className={`form-control formInput ${formErrors.message.length > 0 ? 'error' : null
                                }`}
                                onChange={this.handleChange}
                                placeholder='Message'
                                noValidate
                            ></textarea>
                                {formErrors.message.length > 0 && (
                                    <span className='errorMessage'>{formErrors.message}</span>
                                )}
                            </Col>
                        </Form.Row>
                        <br/>
                        <Form.Row>
                            <Col>
                                <Button className='submit-btn' type='submit'>
                                    Submit
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form>
                </Form.Group>
                <ToastContainer/>
            </Layout>
        );
    }
}

export default Contact;
